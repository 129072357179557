import { useEffect, useState } from 'react';
import { LanguageCode, SecurityRole, User } from 'types/types.d';
import { useTrackedSelector } from 'components/app/store';
import { getDomain } from './windowUtils';
import { SIGNUP } from './routingUtils';

export const passwordMinLength = 8;
export const passwordMaxLength = 100;
export const emailMaxLength = 100;
export const firstNameMaxLength = 50;
export const lastNameMaxLength = 50;
export const clientNameMaxLength = 50;
export const slackChannelNameMaxLength = 50;

/**
 * @return user's language or the default one
 */
export const useUserLanguage = (): LanguageCode => {
  const {
    auth: { user },
  } = useTrackedSelector();

  const [language, setLanguage] = useState(user?.settings?.languageIso6391 || LanguageCode.DE);

  useEffect(() => setLanguage(user?.settings?.languageIso6391 || LanguageCode.DE), [user]);
  return language;
};

/**
 * NOTE: assuming that user has only one role
 *
 * @return true if user has the specified role, false otherwise
 */
export const userHasAnyRole = (user: User | null | undefined, roles: SecurityRole[]): boolean =>
  user != undefined && user && roles?.find((obj) => obj.toString() === user.role) != undefined;

export const userHasPgUserRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.PG_USER]);
export const userHasClientUserRole = (user?: User | null): boolean =>
  userHasAnyRole(user, [
    SecurityRole.CLIENT_ADMIN,
    SecurityRole.CLIENT_USER,
    SecurityRole.SAAS_ADMIN,
    SecurityRole.SAAS_USER,
  ]);
export const userHasSaasClientUserRole = (user?: User | null): boolean =>
  userHasAnyRole(user, [SecurityRole.SAAS_ADMIN, SecurityRole.SAAS_USER]);
export const userHasCreatorRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.CREATOR]);
export const userHasSuperAdminRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.SUPER_ADMIN]);
export const userHasAdminRole = (user?: User | null): boolean =>
  userHasAnyRole(user, [SecurityRole.SUPER_ADMIN, SecurityRole.ADMIN]);

export const userHasSession = (user?: User | null): boolean =>
  userHasAnyRole(user, [
    SecurityRole.SUPER_ADMIN,
    SecurityRole.ADMIN,
    SecurityRole.PG_USER,
    SecurityRole.CLIENT_ADMIN,
    SecurityRole.CLIENT_USER,
    SecurityRole.SAAS_ADMIN,
    SecurityRole.SAAS_USER,
    SecurityRole.CREATOR,
  ]);

export const buildInvitationLink = (code: string): string => `${getDomain()}/#${SIGNUP}?key=${code}`;

export const getFullName = (user: User | null): string => (user ? `${user.firstName} ${user.lastName}` : '');
export const getFullNameFromParts = (firstName: string, lastName: string): string => `${firstName} ${lastName}`;

export const getTermsAndConditions = (): string => {
  return 'https://www.paintgun.io';
};

export const getPrivacyPolicy = (): string => {
  return 'https://www.iubenda.com/privacy-policy/24525838';
};
