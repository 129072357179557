import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOGIN, PASSWORD_CHANGE, PASSWORD_RESET, REGISTER, SIGNUP } from 'utils/routingUtils';
import ChangePassword from 'components/features/public/changePassword';
import ResetPassword from 'components/features/public/resetPassword';
import Register from 'components/features/public/register';
import Login from 'components/features/public/login';
import Signup from 'components/features/public/signup';

/**
 * Handles public home where user is not yet logged in.
 */
export default function Home(): JSX.Element {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={PASSWORD_RESET} element={<ResetPassword />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={SIGNUP} element={<Signup />} />
      <Route path={PASSWORD_CHANGE} element={<ChangePassword />} />
      <Route path="*" element={<Navigate to={LOGIN} />} />
    </Routes>
  );
}
